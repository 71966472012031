export const PAYMENT_METHOD_CONFIG = {
  HEADING: 'Payment method configurations',
  SUBHEADING:
    'Add and manage your configurations where you want to enable payment methods.',
  BUTTON_TEXT: 'Add a configuration',
  ADD: 'Add configuration',
  EDIT: 'Edit configuration',
  NAME_LABEL: 'Configuration name',
  NAME_PACEHOLDER: 'Enter a configuration name',
  PAYMENT_METHOD_EMPTY_MESSAGE: 'Select atleast one payment method',
  CONFIG_NAME_EMPTY_MESSAGE: 'Please enter a configuration name'
}

export const PAYMENT_METHOD_DOMAIN = {
  HEADING: 'Payment method domains',
  SUBHEADING: 'Add and verify your ownership of domain.',
  EDIT_DOMAIN: 'Edit domain for Apple Pay',
  BUTTON_TEXT: 'Add a new domain',
  REGISTER_DOMAIN: 'Register domain for Apple Pay',
  LABEL: 'Provide the domain that you wish to enable Apple Pay for',
  DOMAIN_PACEHOLDER: 'Enter a domain name',
  CAPTION:
    'Example: pos.toasttab.com (top-level domain) or tbd.pos.toasttab.com (sub - domain)',
  VERIFY_INSTRUCTIONS:
    'Save this file as apple-developer-merchantid-domain-association.< br /> Please note that the verification file does not have a file extension.',
  CANCEL: 'Cancel',
  VERIFY: 'Verify',
  REGISTER: 'Register',
  VERIFY_LATER: "I'll do it later",
  GET_VERIFICATION_FILE_LABEL: 'Get the Verification File',
  HOST_VERIFICATION_FILE_LABEL: 'Host the verification file',
  OPEN_NEW_TAB_BUTTON_LABEL: 'apple-developer-merchantid-domain-association',
  DOMAIN_FAILED_MESSAGE: 'Domain could not be created.',
  VERIFICATION_SUCCESS_MESSAGE: 'Domain is verified.',
  VERIFICATION_FAILED_MESSAGE: 'Domain could not be verified.'
}
