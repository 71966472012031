import { useState, useCallback } from 'react'

type ApiMethod = 'GET' | 'POST' | 'DELETE'

interface UseApiResponse<T> {
  loading: boolean
  error: string | null
  data: T | null
  fetchApi: (body?: any) => Promise<void>
}

const useApi = <T>(
  url: string,
  method: ApiMethod,
  partnerGuid: string
): UseApiResponse<T> => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | null>(null)

  const fetchApi = useCallback(
    async (body?: any) => {
      setLoading(true)
      setError(null)
      setData(null)

      try {
        const headers: HeadersInit = {
          'toast-organization-guid': partnerGuid
        }

        if (method === 'POST') {
          headers['Content-Type'] = 'application/json'
        }

        const options: RequestInit = {
          method,
          headers,
          body: method === 'POST' ? JSON.stringify(body) : undefined
        }
        const response = await fetch(url, options)

        if (!response.ok) {
          const errorText = await response.text()
          throw new Error(`Error ${response.status}: ${errorText}`)
        }

        const responseData: T = await response.json()
        setData(responseData)
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error')
        setData(null)
      } finally {
        setLoading(false)
      }
    },
    [url, method, partnerGuid]
  )

  return { loading, error, data, fetchApi }
}

export default useApi
