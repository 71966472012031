import React, { useEffect, useState } from 'react'
import { Modal } from '@toasttab/buffet-pui-modal'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../PaymentMethodsComponent/PaymentMethodConstants'
import { CopyIcon } from '@toasttab/buffet-pui-icons'
import { PaymentMethodDomainType } from '../../hooks/useFetchPaymentMethodDomain'
import { LaunchIcon } from '@toasttab/buffet-pui-icons'

interface DomainModalComponentProps {
  modalIsOpen: boolean
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  onSave: (domain: string) => void
  validate: (domain: string) => void
  verifyMode?: boolean
  setVerifyMode: React.Dispatch<React.SetStateAction<boolean>>
  modalData?: PaymentMethodDomainType | null
}

export const DomainModalComponent: React.FC<DomainModalComponentProps> = ({
  modalIsOpen,
  setModalIsOpen,
  onSave,
  validate,
  verifyMode = false,
  setVerifyMode,
  modalData = null
}) => {
  const [domainName, setDomainName] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [textToCopy, setTextToCopy] = useState<string>('')

  useEffect(() => {
    if (modalIsOpen) {
      if (verifyMode && modalData) {
        setDomainName(modalData.domain)
      } else {
        setDomainName('')
      }
      setTextToCopy('')
    }
  }, [modalIsOpen, modalData])

  useEffect(() => {
    if (domainName) {
      setTextToCopy(
        `${domainName}/.well-known/apple-developer-merchantid-domain-association`
      )
    }
  }, [domainName, modalIsOpen, verifyMode])

  const closeModal = () => {
    setModalIsOpen(false)
    setVerifyMode(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDomainName(e.target.value)
  }

  const handleOpenInNewTab = () => {
    window.open(
      'https://www.toasttab.com/.well-known/ME_prod_toast',
      '_blank',
      'noopener'
    )
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(textToCopy)
  }

  const handleSave = () => {
    setLoading(true)
    try {
      if (verifyMode) {
        validate(domainName)
        closeModal()
      } else {
        onSave(domainName)
        setVerifyMode(true)
      }
    } catch (error) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className='text-default'
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      size='lg'
    >
      <Modal.Header>
        {PAYMENT_METHOD_DOMAIN.REGISTER_DOMAIN}
        <p className='type-subhead font-normal text-secondary mt-2'>
          {PAYMENT_METHOD_DOMAIN.SUBHEADING}
        </p>
      </Modal.Header>
      <Modal.Body className='mt-4'>
        <div className='mb-6'>
          <label
            htmlFor='domain-name'
            className='font-semibold type-default text-default'
          >
            {PAYMENT_METHOD_DOMAIN.LABEL}
          </label>
          <TextInput
            value={domainName}
            name='domain-name'
            onChange={handleInputChange}
            disabled={verifyMode}
            transparentBackground={true}
          />
          <p className='type-caption'>{PAYMENT_METHOD_DOMAIN.CAPTION}</p>
        </div>
        <div className='mb-6'>
          <label className='font-semibold type-default text-default'>
            {PAYMENT_METHOD_DOMAIN.GET_VERIFICATION_FILE_LABEL}
          </label>
          {verifyMode && (
            <>
              <div>
                <Button
                  variant='secondary'
                  size='sm'
                  iconRight={<LaunchIcon accessibility='decorative' />}
                  onClick={handleOpenInNewTab}
                >
                  {PAYMENT_METHOD_DOMAIN.OPEN_NEW_TAB_BUTTON_LABEL}
                </Button>
              </div>
              <p className='type-caption'>
                {PAYMENT_METHOD_DOMAIN.VERIFY_INSTRUCTIONS}
              </p>
            </>
          )}
        </div>

        <div className='mb-6'>
          <label className='font-semibold type-default text-default'>
            {PAYMENT_METHOD_DOMAIN.HOST_VERIFICATION_FILE_LABEL}
          </label>
          {verifyMode && (
            <>
              <p>
                <em>{textToCopy}</em>
              </p>
              <div>
                <IconButton icon={<CopyIcon />} onClick={handleCopy} />
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='flex-grow sm:flex-none'
          variant='link'
          onClick={closeModal}
          disabled={loading}
        >
          {' '}
          {verifyMode
            ? PAYMENT_METHOD_DOMAIN.VERIFY_LATER
            : PAYMENT_METHOD_DOMAIN.CANCEL}
        </Button>
        <Button
          className='flex-grow sm:flex-none'
          onClick={handleSave}
          disabled={loading}
        >
          {verifyMode
            ? PAYMENT_METHOD_DOMAIN.VERIFY
            : PAYMENT_METHOD_DOMAIN.REGISTER}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
