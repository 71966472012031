import { useCallback, useState, useEffect } from 'react'
import { Partner } from '@toasttab/depot-banquet-props'
import { PAYMENT_METHOD_DOMAIN } from '../api/config'
import useApi from './useApi'

interface CreatePaymentMethodDomainResponse {
  domain: string
}

export const useValidatePaymentMethodDomain = (partner: Partner) => {
  const [paymentMethodDomainId, setPaymentMethodDomainId] = useState<
    string | null
  >(null)
  const [config, setConfig] = useState<any | null>(null)

  const {
    loading,
    error,
    data: validateData,
    fetchApi
  } = useApi<CreatePaymentMethodDomainResponse>(
    `${PAYMENT_METHOD_DOMAIN.PATH}/${paymentMethodDomainId}/validate`,
    'POST',
    partner.guid
  )

  // Callback to initiate the validate operation
  const validatePaymentMethodDomain = useCallback(
    async (paymentMethodDomainId: string, config: any) => {
      setPaymentMethodDomainId(paymentMethodDomainId)
      setConfig(config)
    },
    []
  )

  useEffect(() => {
    if (paymentMethodDomainId && config) {
      fetchApi(config)
    }
  }, [paymentMethodDomainId, config, fetchApi])

  return { loading, error, validateData, validatePaymentMethodDomain }
}
