import { CardContainer } from '@toasttab/buffet-pui-card'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PAYMENT_METHOD_DOMAIN } from '../PaymentMethodsComponent/PaymentMethodConstants'
import { useDepotBanquetProps } from '@toasttab/depot-banquet-props'
import { useState, useCallback, useEffect } from 'react'
import { useCreatePaymentMethodDomain } from '../../hooks/useCreatePaymentMethodDomain'
import { DomainModalComponent } from './DomainModalComponent'
import {
  useFetchPaymentMethodDomain,
  PaymentMethodDomainType
} from '../../hooks/useFetchPaymentMethodDomain'
import { TableComponent } from '../ReusableComponents/TableComponent/TableComponent'
import { PaymentIcons } from '../ReusableComponents/PaymentIcons/PaymentIcons'
import { TableColumn } from '../ReusableComponents/TableComponent/TableComponentTypes'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import {
  CheckCircleSelectedIcon,
  WarningOutlineIcon,
  AddIcon
} from '@toasttab/buffet-pui-icons'
import { useValidatePaymentMethodDomain } from '../../hooks/useValidatePaymentMethodDomain'

export const DomainComponent = () => {
  const { partner } = useDepotBanquetProps()
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()

  const { createPaymentMethodDomain, domainDataCreated } =
    useCreatePaymentMethodDomain(partner)
  const { fetchDomains, pmdList } = useFetchPaymentMethodDomain(partner)
  const { validatePaymentMethodDomain, validateData } =
    useValidatePaymentMethodDomain(partner)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pmdId, setPmdId] = useState<string>('')
  const [domainModalData, setModalData] =
    useState<PaymentMethodDomainType | null>(null)
  const [verifyMode, setVerifyMode] = useState(false)

  const domainColumns: TableColumn[] = [
    { heading: 'Domain Name', dataKey: 'domain' },
    {
      heading: 'Verification',
      dataKey: 'applePay',
      isClickable: (row: PaymentMethodDomainType) =>
        row.applePay === 'inactive',
      render: (applePay: string) => (
        <div>{applePay === 'inactive' ? 'verify now' : 'verified'}</div>
      )
    },
    {
      heading: 'Enabled payment methods',
      dataKey: 'paymentMethods',
      render: () => (
        <div className='payment-icons-container'>
          <PaymentIcons paymentType='applePay' />
        </div>
      )
    }
  ]

  useEffect(() => {
    if (domainDataCreated) {
      setPmdId(domainDataCreated.id)
    }
    fetchDomains()
  }, [domainDataCreated])

  useEffect(() => {
    if (validateData) {
      fetchDomains()
    }
  }, [validateData])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setPmdId('')
  }

  const openModalToCreateNewDomain = () => {
    openModal()
    setPmdId('')
    setVerifyMode(false)
    setModalData(null)
  }

  const createNewDomain = useCallback(
    async (domainName: string) => {
      try {
        const createDomainData = { domain: domainName }
        await createPaymentMethodDomain(createDomainData)
      } catch (error) {
        showErrorSnackBar(
          <>
            <WarningOutlineIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.DOMAIN_FAILED_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
      }
    },
    [createPaymentMethodDomain, showErrorSnackBar]
  )

  const openModalToVerifyDomain = useCallback(
    (rowData: PaymentMethodDomainType) => {
      const domainToVerify = pmdList.find((config) => config.id === rowData.id)
      setPmdId(rowData.id)
      if (domainToVerify) {
        setVerifyMode(true)
        setModalData(domainToVerify)
        openModal()
      }
    },
    [pmdList, openModal, verifyMode]
  )

  const validateDomain = useCallback(
    async (domainName: string) => {
      try {
        const validateDomainData = {
          //todo: add input tag for integrationName
          integrationName: domainName,
          domain: domainName
        }
        await validatePaymentMethodDomain(pmdId, validateDomainData)
        showSuccessSnackBar(
          <>
            <CheckCircleSelectedIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.VERIFICATION_SUCCESS_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
        closeModal()
      } catch (error) {
        showErrorSnackBar(
          <>
            <WarningOutlineIcon accessibility='decorative' />{' '}
            {PAYMENT_METHOD_DOMAIN.VERIFICATION_FAILED_MESSAGE}
          </>,
          { autoHideDuration: 4000 }
        )
      }
    },
    [fetchDomains, pmdId, showSuccessSnackBar, showErrorSnackBar, closeModal]
  )

  return (
    <div>
      <CardContainer>
        <div className='flex justify-between mb-5'>
          <div>
            <h2 className='type-headline-5 font-bold'>
              {PAYMENT_METHOD_DOMAIN.HEADING}
            </h2>
            <h3 className='type-subhead text-secondary mt-3'>
              {PAYMENT_METHOD_DOMAIN.SUBHEADING}
            </h3>
          </div>
          <Button
            variant='secondary'
            size='sm'
            iconLeft={<AddIcon accessibility='decorative' />}
            onClick={openModalToCreateNewDomain}
          >
            {PAYMENT_METHOD_DOMAIN.BUTTON_TEXT}
          </Button>
        </div>
        <TableComponent
          columns={domainColumns}
          data={pmdList}
          handleCellClick={openModalToVerifyDomain}
        />
      </CardContainer>
      <DomainModalComponent
        modalIsOpen={isModalOpen}
        setModalIsOpen={closeModal}
        onSave={createNewDomain}
        validate={validateDomain}
        verifyMode={verifyMode}
        setVerifyMode={setVerifyMode}
        modalData={domainModalData}
      />
    </div>
  )
}
